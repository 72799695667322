<template>
  <div>
    <Navbar></Navbar>
    <div class="zol-banner zol-banner--blog t-pt-150 t-pb-150">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 text-center">
            <h2 class="mt-0 t-text-light">{{$t('contact')}}</h2>
            <nav aria-label="breadcrumb">
              <ol id="w0" class="t-list breadcrumbs d-flex justify-content-center align-items-center breadcrumb">
            <li class="">
              <router-link class="t-link  t-link--light-alpha" to="/">{{$t('main')}} /</router-link>
            </li>

              <li class="breadcrumbs_list text-white">
                {{$t('contact')}}
              </li>
            </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div class="t-pt-120 t-pb-120">
      <div class="container" v-if="contact">

        <div class="t-pb-120">
          <div class="container">
            <div class="row">
              <div class="col-md-4 t-mb-30 mb-md-0">
                <div class="cards border">
                  <div class="cards__img mx-auto text-center">
                    <img src="../assets/img/icons/contact-icon-1.png" alt="zolfin" class="img-fluid">
                  </div>
                  <div class="cards__body text-center">
                    <h4 class="text-capitalize cards__body-title">
                      {{$t('address')}}
                    </h4>
                    <p class="mb-0 t-text-heading">
                      {{contact[0].address}}
                    </p>
                  </div>

                </div>
              </div>
              <div class="col-md-4 t-mb-30 mb-md-0">
                <div class="cards border">
                  <div class="cards__img mx-auto text-center">
                    <img src="../assets/img/icons/contact-icon-2.png" alt="zolfin" class="img-fluid">
                  </div>
                  <div class="cards__body text-center">
                    <h4 class="text-capitalize cards__body-title">
                      {{$t('work_day')}}
                    </h4>
                    <p class="mb-0 t-text-heading">

                      {{contact[0].working_days}}

                      {{contact[0].weekend}}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 t-mb-30 mb-md-0">
                <div class="cards border">
                  <div class="cards__img mx-auto text-center">
                    <img src="../assets/img/icons/contact-icon-3.png" alt="zolfin" class="img-fluid">
                  </div>
                  <div class="cards__body text-center">
                    <h4 class="text-capitalize cards__body-title">
                      {{$t('fats_contact')}}
                    </h4>
                    <p class="mb-0 t-text-heading">

                      {{contact[0].phone_number}}
                      <br>
                      {{contact[0].email}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1259.5333072420738!2d69.2818285217766!3d41.337311672760116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8b11d89b0367%3A0x51142a231734d8ae!2sUzbektourism!5e0!3m2!1sru!2s!4v1672314795385!5m2!1sru!2s" width="auto" height="600" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>    </div>
    </div>

    <div class="back-to-top">
    <span class="back-top">
        <i class="las la-angle-up"></i>
    </span>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "../components/Header/Navbar";
import Footer from "../components/Header/Footer";
export default {
  components: {Footer, Navbar},
  data(){
    return{
      contact:[]
  }
  },
  mounted() {
    this.$axiosDefault.get('contact',
        {
          headers: {
            'Accept-Language': localStorage.getItem('lang')
          }
        })
    .then(res=>{
      this.contact=res.data
    })

  }
}
</script>
