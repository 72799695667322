<template>
  <div>
    <Navbar></Navbar>
    <div class="t-pt-120 mt-4 t-pb-175">
      <div class="container">
        <div class="row">
          <div class="col-md-4 offset-md-4">
            <h3>{{$t('login')}}</h3>

            <form id="login-form" @submit.prevent="login()">
              <input type="hidden" name="_csrf"
                     value="rPXj6wdNQDgBS15fShychHeEVbflubKi_TZHVmLJlVHrtLKbMDltSW0CCWoQf-W2Ickb3cjb1uCpVSkbFJ7xFA==">
              <div class="mb-3 field-loginform-username required">
                <label class="form-label" for="loginform-username">{{$t('email')}}</label>
                <input type="text" id="loginform-username" placeholder="someone@milliyboylik.uz" class="form-control" name="LoginForm[username]"
                       aria-required="true" data-plugin-inputmask="inputmask_aaec388b"
                    v-model="email"
                >
                <div class="invalid-feedback"></div>
              </div>
              <div class="mb-3 field-loginform-password required">
                <label class="form-label" for="loginform-password">{{$t('password')}}</label>
                <input type="password" id="loginform-password" class="form-control" name="LoginForm[password]" value=""
                       aria-required="true"
                       v-model="password"
                >

                <div class="invalid-feedback"></div>
              </div>
              <div class="form-group">
                <button type="submit" class="btn btn-primary" name="login-button">{{$t('login')}}</button>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "../../components/Header/Navbar";
import Footer from "../../components/Header/Footer";

export default {
  components: {Footer, Navbar},
  data(){
    return{
      password:'',
      email:''
    }
  },
  methods:{
    login(){
      this.$axiosDefault.post('/users/login/',{
        "email":this.email,
        "password":this.password
      })
          .then(res=>{
            localStorage.setItem('reg_token',res.data.access)
            this.$store.dispatch('getMe')
            this.$router.push('/')

          })
          .catch(()=>{
            this.$toast("Email yoki parol xato ", {
              timeout: 2000,
              type:"error"
            });
          })

    }
  }
}
</script>
