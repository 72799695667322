<template>
  <div class="aplication_table">
    <Navbar></Navbar>
    <div  class="container my-4">
      <div class="css_table">
        <table class="styled-table">
          <thead>
          <tr>
            <th>{{$t('id_n')}}</th>
            <th>{{$t('date')}}</th>
            <th>{{$t('name')}}</th>
            <th>{{$t('object')}}</th>
            <th>{{$t('cate')}}</th>
            <th>{{$t('status')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr class="active-row" v-for="item in items.results" :key="item">
            <td >{{item.id}}</td>
            <td >{{new Date(item.created_at).toLocaleDateString()}}</td>
            <td >{{item.full_name}}</td>
            <td >{{item.title}}</td>
            <td >{{item.item_category_name}}</td>

            <td>
              <span class="label label-warning bg-primary p-1 text-white" v-if="item.status==0">{{$t('wait')}}</span>
              <span class="label label-warning bg-success p-1 text-white" v-if="item.status==1">{{$t('accs')}}</span>
              <span class="label label-warning bg-danger p-1 text-white" v-if="item.status==2">{{$t('error')}}</span>
            </td>
          </tr>
          <!-- and so on... -->
          </tbody>
        </table>
      </div>

    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "../components/Header/Navbar";
import Footer from "../components/Header/Footer";
export default {
  components:{Footer, Navbar},
  data(){
    return{
      items:[]
    }
  },
  mounted() {
    this.$axiosDefault.get('items')
    .then(res=>{
      this.items=res.data
    })
  }
}

</script>
<style>
@media (max-width: 1200px) {
  .aplication_table .card{
    overflow:hidden;
  }
  .aplication_table .card-body{
    overflow-x: scroll;
  }

}
.table.b-table.b-table-no-border-collapse{
  border-collapse: 0;
}
.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.styled-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
}
.styled-table th,
.styled-table td {
  padding: 12px 15px;
}
.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}
.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}
</style>
