<template>
  <div>
    <Navbar></Navbar>
    <div class="container">

      <div>
        <div class="container">
          <div class="row">
            <div class="col-md-4" v-for="(itm,ind) in news.results" :key="ind">
              <div class="price-card t-bg-light">
                <div class="t-pl-30 t-pr-30 text-center mx-auto">
                  <a href="#" class="t-link">
                    <img src="../../assets/img/photo.jpg" alt="" class="img-fluid w-100"/>
                  </a>
                </div>
                <div class="t-pl-30 t-pr-30 t-mt-30">
                  <a href="#" class="t-link t-link-primary xsm-text">
                    <b-icon icon="calendar2" ></b-icon>
                    {{new Date(itm.published_date).toLocaleDateString()}}
                  </a>
                  <br>
                  <a href="#" class="t-link t-link-primary xsm-text">
                    <b-icon icon="eye" ></b-icon>
                    {{itm.views}}
                  </a>
                  <h4 class="text-capitalize t-mt-10">
                    <a href="/news/view?id=3" class="t-link t-link--alpha">
                      {{itm.title}}
                    </a>
                  </h4>

                </div>

                <div class="t-pl-30 t-pr-30 t-mt-30">
                  <router-link :to="`/news/view?id=${itm.id}`" class="t-link t-link--alpha">
                        <span class="cards__footer-icon">
                           <b-icon icon="plus-circle" ></b-icon>
                        </span>
                    <span class="text-capitalize font-weight-bold">
                            Batafsil
                        </span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "../../components/Header/Navbar";
import Footer from "../../components/Header/Footer";
import axios from "axios";

export default {
  components:{
    Footer,
    Navbar
  },
  data(){
    return{
      news:[]
    }
  },
  mounted() {
    axios.get(`news`)
        .then(res => {
          this.news = res.data
        })
  }
}
</script>
