<template>
  <div>
    <Navbar></Navbar>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h4>{{$t('menu3')}}</h4>
          <form id="w0" @submit.prevent="create()">
            <input type="hidden" name="_csrf"
                   value="idjtFt_yrb80kgHbS6ZUfASU9R1EBjostIg2JrIk8SvmtppXvIXIxwPeMJo7lB8RXPa9LAFoVnPsxkxPgWyoHQ==">
            <div class="row">
              <div class="col-md-4">
                <div class="mb-3 field-signupform-name required">
                  <label class="form-label" for="signupform-name">{{$t('object')}}</label>
                  <input type="text" v-model="title" id="signupform-name" class="form-control" name="SignupForm[name]" maxlength="255"
                         aria-required="true">

                  <div class="invalid-feedback"></div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3 field-signupform-surname required">
                  <label class="form-label" for="signupform-surname">{{$t('date')}}</label>
                  <input type="text" v-model="creation_date" id="signupform-surname" class="form-control" name="SignupForm[surname]"
                         maxlength="255" aria-required="true">

                  <div class="invalid-feedback"></div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3 field-signupform-middle_name required">
                  <label class="form-label" for="signupform-middle_name">{{$t('status')}} </label>
                    <div>
                      <select class="form-control" v-model="condition">
                        <option value="1">Yaxshi</option>
                        <option value="2">Qoniqarli</option>
                        <option value="3">Ta’mirtalab</option>
                      </select>

                    </div>
                  <div class="invalid-feedback"></div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3 field-signupform-middle_name required">
                  <label class="form-label" for="signupform-middle_name">{{$t('cate')}} </label>
                  <div>
                    <select class="form-control" v-model="item_categor">
                      <option v-for="(item,ind) in item_category" :key="ind" :value="item.id">{{item.title}}</option>
                    </select>

                  </div>
                  <div class="invalid-feedback"></div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="mb-3 field-signupform-middle_name required">
                  <label class="form-label" for="signupform-middle_name">{{$t('comment')}}</label>
                  <input type="text" id="signupform-middle_name" class="form-control" v-model="comment" name="SignupForm[middle_name]"
                         maxlength="255" aria-required="true">

                  <div class="invalid-feedback"></div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3 field-signupform-middle_name required">
                  <label class="form-label" for="signupform-middle_name">{{$t('price')}}</label>
                  <div>
                    <div>
                      <input type="checkbox" id="check_money" v-model="is_price" @input="is_price_m=0">
                      <label for="check_money" class="mx-3">{{$t('free')}} </label>
                    </div>

                    <input type="text" id="signupform-middle_name" v-model="is_price_m" :class="{'disabled':is_price}"  class="form-control" name="SignupForm[middle_name]"
                           maxlength="255" aria-required="true">


                  </div>
                  <div class="invalid-feedback"></div>
                </div>
              </div>
              <div class="col-12">
                <div class="create_form mt-4">
                  <img v-for="img in imageUrl" :key="img" :src="img" class="my-4 mx-4" style="object-fit: cover" width="300px" height="300px"   alt="">
                  <input type="file" class="form-control d-none"
                         id="cr_image1"
                         @change="trySubmitFile"
                         accept="*"
                         name="datafiles"
                         ref="datafiles"

                  >
                </div>
                <label class="btn btn-primary my-3" for="cr_image1">{{$t('foto')}}</label>
              </div>
            </div>

            <div class="form-group my-5 mt-3">
              <button type="submit" class="btn btn-success" name="login-button">{{$t('save')}}</button>
            </div>

          </form>
        </div>
      </div>

    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "../../components/Header/Navbar";
import Footer from "../../components/Header/Footer";
import axios from "axios";

export default {
  components: {Footer, Navbar},
  data(){
    return{
      item_category:[],
      is_price:false,
      comment:'',
      title:'',
      condition:'',
      item_images:'',
      imageUrl:[],
      myImages:[],
      files_name:[],
      creation_date:'',
      item_categor:'',
      imgs:[],
      is_price_m:0
    }
  },
  mounted() {
    axios.get(`item-category/`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('reg_token')}`,
            'Accept-Language':localStorage.getItem('lang')
          }
        }
    )
        .then(res=>{
          this.item_category=res.data
        })
  },
  methods:{

    trySubmitFile(e) {
      this.imageUrl=[]
      for (let i = 0; i < e.target.files.length; i++) {
        this.files_name.push(e.target.files[i])
      }
      var files = Array.from(e.target.files);
      console.log(typeof (files))
      files.forEach((file) => {
        this.myImages.push(file);
        var reader = new FileReader();
        var vm = this;
        reader.onload = function(e) {
          vm.imageUrl.push(e.target.result);
        };
        reader.readAsDataURL(file);
      });
    },
    create(){
      const form = new FormData();
      form.append("image", this.myImages[0]);
      form.append("title", this.title);
      form.append("condition", this.condition);
      form.append("comment", this.comment);
      form.append("price", this.is_price_m);
      form.append("item_category", this.item_categor);
      form.append("creation_date", this.creation_date);
      this.$axiosDefault.post(`send-request/`,form,
          {
            headers:{
              'Authorization': `Bearer ${localStorage.getItem('reg_token')}`,
            }
          })

          .then(()=>{
            this.$toast("Ariza jo'natildi", {
              timeout: 10000,
              type:"success"
            });
            this.$router.push('/request')
          })
          .catch(()=>{
            this.$toast("Ariza jo'natishda xatolik iltimos qayta urunib ko'ring", {
              timeout: 2000,
              type:"error"
            });
          })
    }
  }
}
</script>
<style>
.disabled{
  pointer-events: none;
  background-color: #e9ecef;
}
</style>
