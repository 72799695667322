<template>
  <div class="redesign-inner">
    <Navbar></Navbar>
      <div class="container">
        <div id="myTabContent" class="tab-content card">
          <div class="container">
            <div class="table-responsive">

              <div id="w4" class="grid-view">
                <table class="table styledTable">
                  <colgroup>
                    <col>
                    <col class="col-md-8">
                    <col class="col-md-2">
                    <col class="col-md-1">
                    <col class="col-md-1">
                  </colgroup>
                  <thead>
                  <tr>
                    <th>№</th>
                    <th>
                      <a href="" data-sort="service_id">{{$t('object')}}</a>
                    </th>
                    <th>
                      <a href="" data-sort="service_id">{{$t('cate')}} </a>
                    </th>
                    <th>
                      <a href="" data-sort="service_id">{{$t('date')}} </a>
                    </th>
                    <th>
                      <a class="desc" href="" data-sort="created_date">
                        {{$t('status')}}
                      </a>
                    </th>
                    <th><a href="" data-sort="status">Narxi (taxminiy)</a></th>
                    <th><a href="" data-sort="status">Yaratilgan vaqt</a></th>
                    <th><a href="" data-sort="status">Holati</a></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr data-key="73339680" v-for="(item,ind) in requests" :key="ind">
                    <td>
                      <a href="#">{{item.id}}</a>
                    </td>
                    <td>
                      <a href="#">{{item.title}}</a>
                    </td>
                    <td>
                      <a href="#">{{item.item_category_name}}</a>
                    </td>
                    <td>
                      <a href="#">{{item.creation_date}}</a>
                    </td>
                    <td>
                      <a href="#" v-if="item.condition==1">
                        Yaxshi
                      </a>
                      <a href="#" v-if="item.condition==2">
                        Qoniqarli
                      </a>
                      <a href="#" v-if="item.condition==3">
                        Ta’mirtalab
                      </a>
                    </td>
                    <td>
                      <a href="#">{{item.price}}</a>
                    </td>
                    <td>
                      <a href="#">{{new Date(item.created_at).toLocaleDateString()}} {{new Date(item.created_at).toLocaleTimeString()}}</a>
                    </td>
                    <td>
                      <span class="label label-warning" v-if="item.status==0">{{$t('wait')}}</span>
                      <span class="label label-warning bg-success" v-if="item.status==1">{{$t('accs')}}</span>
                      <span class="label label-warning bg-danger" v-if="item.status==2">{{$t('error')}}</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "../../components/Header/Navbar";
import Footer from "../../components/Header/Footer";
import axios from "axios";

export default {
  components: {Footer, Navbar},
  data(){
    return{
      requests:[]
    }
  },
  mounted() {
    axios.get(`users/me/`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('reg_token')}`,
            'Accept-Language':localStorage.getItem('lang')
          }
        }
    )
        .then(res=>{
          axios.get(`users/${res.data.id}/requests/`,
              {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('reg_token')}`,
                  'Accept-Language': localStorage.getItem('lang')
                }
              }
          )
              .then(ress => {
                this.requests = ress.data
              })
        })


  }
}
</script>
<style>
#myTabContent{
  background-color: white;
  border: 1px solid #DDDDDD;
  border-top: none;
  padding: 20px;
  margin-top: 50px;
  margin-bottom: 100px;
}
.redesign-inner {
  background-color: #f0f7fb;
}
.styledTable a {
  color: #4b4b4b;
}
.styledTable tbody tr td a {
  color: #535353;
  text-decoration: none;
  display: block;
  padding: 5px 0;
  width: 100%;
  height: 100%;
}
.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}
.label-warning {
  background-color: #f0ad4e;
}
</style>

