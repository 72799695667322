<template>
  <div class="news_main">
    <Navbar></Navbar>
    <div class="container">

      <div>
        <div class="container">
          <div class="col-xl-12 col-12">

            <div class="newssingl">
              <div class="newssingil_title text-center" >{{news.title}}</div>
              <div class="newssingil_img text-center" >
                <img :src="news.image_url">
              </div>
              <div class="newssingil_description">
                {{news.description}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "../../components/Header/Navbar";
import Footer from "../../components/Header/Footer";
import axios from "axios";

export default {
  components: {
    Footer,
    Navbar
  },
  data() {
    return {
      news: []
    }
  },
  mounted() {
    axios.get(`news/${this.$route.query.id}`,
        {
          headers: {
            'Accept-Language': localStorage.getItem('lang')
          }
        }
    )
        .then(res => {
          this.news = res.data.data
        })
  }
}
</script>
<style>
.newssingl {
  background: #fff;
  padding: 50px;
  margin-bottom: 100px;
  margin-top: 50px;
}
.news_main{
  background: #f6f6f9;
}
.newssingil_title {
  font-family: Monsserat;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #01011c;
  margin-bottom: 15px;
}
.newssingil_description{
  margin-top: 50px;
}
</style>
