<template>
  <div>
    <Navbar></Navbar>

    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "../../components/Header/Navbar";
import Footer from "../../components/Header/Footer";
export default {
  components: {Footer, Navbar},
  mounted() {
    const form = new FormData();
    form.append("token", this.$route.query.token);
    this.$axiosDefault.post('users/verify/',form)
        .then(()=>{
          localStorage.setItem('reg_token',this.$route.query.token)
          this.$router.push('/')
        })
        .catch(error=>{
          console.log(error.message)
        })
  }
}
</script>
