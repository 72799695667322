<template>
  <div>
    <Navbar></Navbar>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h4>Shaxsiy ma'lumotlar</h4>
          <form id="w0" action="/site/signup" method="post">
            <input type="hidden" name="_csrf"
                   value="rw7r-BLNW6m5qBnoC5TUgosI1rUNO2OArR1wV5vQ-j6WXNKefqcTxYrfb4ddppPQ5F-l831NOubrbgcwrLPKfw==">
            <div class="row">
              <div class="col-md-4">
                <div class="mb-3 field-signupform-name required">
                  <label class="form-label" for="signupform-name">Ism</label>
                  <input type="text" id="signupform-name" class="form-control" name="SignupForm[name]" maxlength="255"
                         aria-required="true">

                  <div class="invalid-feedback"></div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3 field-signupform-surname required">
                  <label class="form-label" for="signupform-surname">Familiya</label>
                  <input type="text" id="signupform-surname" class="form-control" name="SignupForm[surname]"
                         maxlength="255" aria-required="true">

                  <div class="invalid-feedback"></div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3 field-signupform-middle_name required">
                  <label class="form-label" for="signupform-middle_name">Sharif</label>
                  <input type="text" id="signupform-middle_name" class="form-control" name="SignupForm[middle_name]"
                         maxlength="255" aria-required="true">

                  <div class="invalid-feedback"></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="mb-3 field-signupform-passport_seria required">
                  <label class="form-label" for="signupform-passport_seria">Pasport seriya</label>
                  <input type="text" id="signupform-passport_seria" class="form-control"
                         name="SignupForm[passport_seria]" maxlength="2" aria-required="true">

                  <div class="invalid-feedback"></div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3 field-signupform-passport_number required">
                  <label class="form-label" for="signupform-passport_number">Pasport raqam</label>
                  <input type="number" id="signupform-passport_number" class="form-control"
                         name="SignupForm[passport_number]" aria-required="true">

                  <div class="invalid-feedback"></div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3 field-signupform-passport_source required">
                  <label class="form-label" for="signupform-passport_source">Pasport qayerdan olingan</label>
                  <input type="text" id="signupform-passport_source" class="form-control"
                         name="SignupForm[passport_source]" maxlength="1024" aria-required="true">

                  <div class="invalid-feedback"></div>
                </div>
              </div>
            </div>
            <hr>

            <div class="form-group float-xl-start my-4 mt-3">
              <button type="submit" class="btn btn-success" name="login-button">Saqlash</button>
            </div>

          </form>
        </div>
      </div>

    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "../../components/Header/Navbar";
import Footer from "../../components/Header/Footer";

export default {
  components: {Footer, Navbar}
}
</script>
