<template>
  <div class="change_bg">

    <Navbar></Navbar>
    <section>

      <div class="container">
        <div class="m_card">

          <div class="">

            <div class="tab-page">
              <div class="tab d-flex">
                <button class="tablinks active d-flex" onclick="openCity(event, 'London')">{{$t('stark')}}</button>
              </div>


              <div id="main" class="tabcontent" style="display: block">
                <h3 style="padding-top: 15px">{{$t('stark')}}</h3>

                <form id="w0" @submit.prevent="mainSend()">
                  <input type="hidden" name="_csrf-frontend"
                         value="jQbqq5l7GjMdaGbjhNRVDZVXA7YCq-IAOr-IqgPqGpX5fLLS9T5MeXM6Kbf9o2x1oiNI4nPulUN26cPFcJN75g==">
                  <div class="row">
                    <div class="col-md-12 input">
                      <select name="CorruptionForm[type]" v-model="agency" id="" class="form-control">
                        <option value="Madaniy meros agentligi">Badiiy ekspertiza boshqarmasi</option>
                        <option value="Madaniy meros agentligi">Madaniy meros agentligi</option>
                        <option value="Buxoro viloyati madaniy meros  boshqarmasi">Buxoro viloyati madaniy meros  boshqarmasi</option>
                        <option value="Samarqand viloyati madaniy meros  boshqarmasi">Samarqand viloyati madaniy meros  boshqarmasi</option>
                        <option value="Andijon viloyati madaniy meros  boshqarmasi">Andijon viloyati madaniy meros  boshqarmasi</option>
                        <option value="Namangan viloyati madaniy meros  boshqarmasi">Namangan viloyati madaniy meros  boshqarmasi</option>
                        <option value="Farg'ona viloyati madaniy meros  boshqarmasi">Farg'ona viloyati madaniy meros  boshqarmasi</option>
                        <option value="Navoiy viloyati madaniy meros  boshqarmasi">Navoiy viloyati madaniy meros  boshqarmasi</option>
                        <option value="Qashqadaryo viloyati madaniy meros  boshqarmasi">Qashqadaryo viloyati madaniy meros  boshqarmasi</option>
                        <option value="Surxondaryo viloyati madaniy meros  boshqarmasi">Surxondaryo viloyati madaniy meros  boshqarmasi</option>
                        <option value="Sirdaryo viloyati madaniy meros  boshqarmasi">Sirdaryo viloyati madaniy meros  boshqarmasi</option>
                        <option value="Jizzax viloyati madaniy meros  boshqarmasi">Jizzax viloyati madaniy meros  boshqarmasi</option>
                        <option value="Toshkent shahar madaniy meros  boshqarmasi">Toshkent shahar madaniy meros  boshqarmasi</option>
                        <option value="Toshkent viloyati madaniy meros  boshqarmasi">Toshkent viloyati madaniy meros  boshqarmasi</option>
                        <option value="Qoraqalpog‘iston Respublikasi madaniy meros  boshqarmasi">Qoraqalpog‘iston Respublikasi madaniy meros  boshqarmasi</option>
                      </select>
                    </div>
                    <div class="col-md-12 input">
                      <label for="">Xizmatni baholash</label>
                      <select v-model="checks" name="CorruptionForm[type]" id="" class="form-control">
                        <option value="A'lo">A'lo</option>
                        <option value="Yaxshi">yaxshi</option>
                        <option value="Qoniqarsiz">Qoniqarsiz</option>
                      </select>
                    </div>
                    <div class="col-md-12 input">
                      <label for="">Sizga qancha muddatda xizmat ko'rsatildi</label>
                      <select v-model="date" name="CorruptionForm[type]" id="" class="form-control">
                        <option value="1 soat">1 soat</option>
                        <option value="1 kun ">1 kun</option>
                        <option value="Kuttirib quyishdi">Uzoq vaqt</option>
                      </select>
                    </div>
                    <div class="col-md-12 input">

                      <div class="text_areas1">
                        <textarea class="form-control" v-model="quiz" placeholder="Taklif va etirozlar"></textarea>
                      </div>
                    </div>

                  </div>
                  <button type="submit" class="btn btn-success input">Saqlash</button>
                </form>

              </div>


              <br>

            </div>
          </div>
        </div>

      </div>
    </section>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "../components/Header/Navbar";
import Footer from "../components/Header/Footer";

export default {
  components: {
    Footer,
    Navbar
  },
  data() {
    return {
      isActive_m: false,
      dataitem: {},
      quiz:'',
      agency:'',
      date:'',
      checks:''
    }
  },
  methods: {
    mainSend(){
      this.$axiosDefault.post('https://backend.madaniymeros.uz/v1/api/appeal/',{
            "full_name":this.agency,
            "phone_number":this.checks,
            "email":`${this.quiz}info@madaniymeros.uz`,
            "body":this.quiz
          },
      )
          .then(()=>{
            this.$toast("Xizmatdan foydalanganingiz uchun rahmat", {
              timeout: 2000,
              type:"success"
            });
            this.$router.push('/')
          })
          .catch(()=>{

            this.$toast("Malumotlar to'liq to'ldirilmadi", {
              timeout: 2000,
              type:"error"
            });
          })
    },
    open_modal() {

    },
    myFunck(item = item) {
      this.dataitem = item
    },
    monitoring(){
      this.isActive_m=true
    }
  }
}
</script>
<style>
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}

.tab-page {
  padding: 50px 5px;
  margin: 50px 0;
}

.input {
  margin-top: 25px;

}

.input label {
  font-size: 14px;
  color: black;
}
</style>
