<template>
  <div>
    <Navbar></Navbar>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h4>{{$t('shaxs')}}</h4>
          <form id="w0" @submit.prevent="signUp()">
            <input type="hidden" name="_csrf"
                   value="pHCLX0QYEN0zehqXIBbu4rzqOk8gRkmqTU3r7aNS2YHCG8QOcSlepGAyfNR_XIuoyqZPFmwvBNpgIZ2D1jaosg==">
            <div class="row">
              <div class="col-md-4">
                <div class="mb-3 field-signupform-name required">
                  <label class="form-label" for="signupform-name">{{$t('first_name')}}</label>
                  <input type="text" id="signupform-name" class="form-control" name="SignupForm[name]" maxlength="255"
                         aria-required="true" v-model="first_name">

                  <div class="invalid-feedback"></div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3 field-signupform-surname required">
                  <label class="form-label" for="signupform-surname">{{$t('last_name')}}</label>
                  <input type="text" id="signupform-surname" class="form-control" name="SignupForm[surname]"
                         maxlength="255" aria-required="true" v-model="last_name">

                  <div class="invalid-feedback"></div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3 field-signupform-middle_name required">
                  <label class="form-label" for="signupform-middle_name">{{$t('middle_name')}}</label>
                  <input type="text" id="signupform-middle_name" class="form-control" name="SignupForm[middle_name]"
                         maxlength="255" aria-required="true" v-model="patronymic">

                  <div class="invalid-feedback"></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="mb-3 field-signupform-passport_seria required">
                  <label class="form-label" for="signupform-passport_seria">{{$t('address')}}</label>
                  <input type="text" id="signupform-passport_seria" class="form-control" name="SignupForm[passport_seria]"
                     aria-required="true"
                  v-model="address">

                  <div class="invalid-feedback"></div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3 field-signupform-passport_seria required">
                  <label class="form-label" for="signupform-passport_seria">{{$t('email')}}</label>
                  <input type="email" id="signupform-passport_seria" class="form-control" name="SignupForm[passport_seria]"
                         aria-required="true"
                         v-model="email">

                  <div class="invalid-feedback"></div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3 field-signupform-passport_seria required">
                  <label class="form-label" for="signupform-passport_seria">{{$t('pass')}}</label>
                  <input type="text" id="signupform-passport_seria" class="form-control" name="SignupForm[passport_seria]"
                         maxlength="2" aria-required="true" v-model="passport_serias">

                  <div class="invalid-feedback"></div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3 field-signupform-passport_number required">
                  <label class="form-label" for="signupform-passport_number">{{$t('shax')}}</label>
                  <input type="number" id="signupform-passport_number" class="form-control"
                         name="SignupForm[passport_number]" aria-required="true" v-model="passport_number">

                  <div class="invalid-feedback"></div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3 field-signupform-passport_number required phone_number">
                  <label class="form-label" for="signupform-passport_number">{{$t('phone')}}</label>
                  <phone-mask-input
                      v-model="phone_number"
                      autoDetectCountry
                      wrapperClass="wrraper-example"
                      inputClass="input-example"
                      flagClass="flag-example"
                  />

                  <div class="invalid-feedback"></div>
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3 field-signupform-password required">
                  <label class="form-label" for="signupform-password">{{$t('password')}}</label>
                  <input type="password" id="signupform-password" class="form-control is-invalid"
                         name="SignupForm[password]" aria-required="true" aria-invalid="true"
                      v-model="password"
                  >

                </div>
              </div>
            </div>

            <div class="form-group float-right mt-3 my-4">
              <button type="submit" class="btn btn-success" name="login-button">{{$t('reg')}}</button>
            </div>

          </form>
        </div>
      </div>

    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "../../components/Header/Navbar";
import Footer from "../../components/Header/Footer";
import PhoneMaskInput from  "vue-phone-mask-input";
export default {
  components: {Footer, Navbar,PhoneMaskInput},
  data(){
    return{
      address:'',
      email:'',
      first_name:"",
      last_name:'',
      passport_number:'',
      passport_serias:'',
      password:'',
      patronymic:'',
      phone_number:''
    }
  },
  methods:{
    signUp(){
      this.$axiosDefault.post('/users/register/',{
          "address":this.address,
          "email":this.email,
          "first_name":this.first_name,
          "last_name":this.last_name,
          "passport_number":this.passport_number,
          "passport_serias":this.passport_serias,
          "password":this.password,
          "patronymic":this.patronymic,
          "phone_number":this.phone_number
      })
      .then(()=>{
        this.$toast("Emailga xabar jo'natildi tekshirib ko'ring", {
          timeout: 10000,
          type:"success"
        });
        // this.$router.push('/site/login')
      })
      .catch(()=>{
        this.$toast("Ma'lumotlar hammasi kiritilmagan yoki bu telefon raqam oldin ro'yxatdan o'tgan", {
          timeout: 2000,
          type:"error"
        });
      })


    }
  }
}
</script>
