<template>
  <div>
    <Navbar></Navbar>
    <div class="t-pt-120 t-pb-175">
      <div class="container">
        <div class="row">
          <div class="col-md-4 offset-md-4">
            <h3>{{$t('reg')}}</h3>

            <form id="login-form" >
              <input type="hidden" name="_csrf"
                     value="rPXj6wdNQDgBS15fShychHeEVbflubKi_TZHVmLJlVHrtLKbMDltSW0CCWoQf-W2Ickb3cjb1uCpVSkbFJ7xFA==">
              <div class="mb-3 field-loginform-username required">
                <label class="form-label" for="loginform-username">Telefon raqam</label>
                <input type="text" id="loginform-username" class="form-control" >
                <div class="invalid-feedback"></div>
              </div>
              <div class="mb-3 field-loginform-password required">
                <label class="form-label" for="loginform-password">Parol</label>
                <input type="password" id="loginform-password" class="form-control" >
                <div class="invalid-feedback"></div>
              </div>
              <div class="my-4 text-end" >
                <router-link to="/site/phone-reg">
                  Telefon raqam orqali ro'yxatdan o'tish
                </router-link>
              </div>
              <div class="form-group text-center">
                <button type="submit" class="btn btn-primary" name="login-button">{{$t('reg')}}</button>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "../../components/Header/Navbar";
import Footer from "../../components/Header/Footer";

export default {
  components: {Footer, Navbar}
}
</script>
